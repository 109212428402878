<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import axios from "axios";

let user = JSON.parse(localStorage.getItem("user"));
let token = user.token;

export default {
  page: {
    title: "Personel Güncelleme",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Personel Güncelleme",
      items: [
        {
          text: "RTS",
          href: "/",
        },
        {
          text: "Personel Listesi",
          href: "/employee",
        },
        {
          text: "Personel Güncelleme",
          active: true,
        },
      ],
      isAlertVisibleSuccess: false,
      isAlertVisibleDanger: false,
      values: {
        name: "",
        citizenship_number: "",
        birtday: "",
        salary: "",
        iban: "",
        status: "",
        job_start_date: "",
        insurance_start_date: "",
        job_end_date: "",
        job_end_reason: "",
      },
      statusOptions: [
        { value: 1, text: "Aktif" },
        { value: 2, text: "Pasif" },
      ],
    };
  },
  mounted() {
    const itemId = this.$route.params.id;
    const api_url = process.env.VUE_APP_BASEURL + `/employee/${itemId}`;

    axios
      .get(api_url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        this.values = response.data?.data;
      })
      .catch((error) => {
        console.error(error);
      });
  },
  methods: {
    showAlertSuccess() {
      this.isAlertVisibleSuccess = true;
    },
    showAlertDanger() {
      this.isAlertVisibleDanger = true;
    },
    updateItem() {
      const itemId = this.$route.params.id;
      const api_url = process.env.VUE_APP_BASEURL + `/employee/${itemId}`;
      const data = {
        name: this.values.name,
        citizenship_number: this.values.citizenship_number,
        birtday: this.values.birtday,
        salary: this.values.salary,
        iban: this.values.iban,
        status: this.values.status,
        job_start_date: this.values.job_start_date,
        insurance_start_date: this.values.insurance_start_date,
        job_end_date: this.values.job_end_date === '0000/00/00' ? "" : this.values.job_end_date,
        job_end_reason: this.values.job_end_reason,
      }
      axios
        .put(api_url, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          this.showAlertSuccess();
        })
        .catch(() => {
          this.showAlertDanger();
        });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row justify-content-center">
      <div class="col-12 col-md-9">
        <div class>
          <b-alert v-if="isAlertVisibleSuccess" show variant="success"
            >Başarıyla güncellendi.</b-alert
          >
          <b-alert v-if="isAlertVisibleDanger" show variant="danger"
            >Hata! Lütfen daha sonra tekrar deneyiniz.</b-alert
          >
        </div>
        <div class="card mt-4">
          <div class="card-body">
            <form @submit.prevent="updateItem">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group mb-3">
                    <label for="name">Personel Adı:</label>
                    <input
                      v-model="values.name"
                      type="text"
                      class="form-control"
                      id="name"
                      required
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mb-3">
                    <label for="citizenship_number">TCK Numarası:</label>
                    <input
                      v-model="values.citizenship_number"
                      type="text"
                      class="form-control"
                      id="citizenship_number"
                      maxlength="11"
                      required
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mb-3">
                    <label for="birtday">Doğum Günü Tarihi:</label>
                    <input
                      v-model="values.birtday"
                      id="birtday"
                      type="date"
                      class="form-control"
                      required
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mb-3">
                    <label for="salary">Maaş:</label>
                    <input
                      v-model="values.salary"
                      type="text"
                      class="form-control"
                      id="salary"
                      required
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <label for="iban">IBAN:</label>
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <div class="input-group-text">TR</div>
                    </div>
                    <input
                      v-model="values.iban"
                      type="number"
                      class="form-control"
                      id="iban"
                      maxlength="29"
                      required
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mb-3">
                    <label for="job_start_date">İşe Başlama Tarihi:</label>
                    <input
                      v-model="values.job_start_date"
                      id="job_start_date"
                      type="date"
                      class="form-control"
                      required
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mb-3">
                    <label for="insurance_start_date"
                      >Sıgorta Başlangıç Tarihi:</label
                    >
                    <input
                      v-model="values.insurance_start_date"
                      id="insurance_start_date"
                      type="date"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mb-3">
                    <label for="job_end_date">İşten Ayrılma Tarihi:</label>
                    <input
                      v-model="values.job_end_date"
                      id="job_end_date"
                      type="date"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group mb-3">
                    <label for="status">Aktif Durumu:</label>
                    <select v-model="values.status" class="form-control">
                      <option v-for="option in statusOptions" :key="option.value" :value="option.value">
                        {{ option.text }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group mb-3">
                    <label for="job_end_reason">İşten Ayrılma Sebebi:</label>
                    <textarea
                      v-model="values.job_end_reason"
                      class="form-control"
                      id="job_end_reason"
                      rows="4"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-end align-items-center">
                <button type="submit" class="btn btn-primary">Güncelle</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
